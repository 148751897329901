import { Link } from "gatsby"
import React from "react"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import "./kurzy.scss"

import ImgKurzy1 from "../../images/tanecni-kurzy/tanecni-kurzy-1.jpg"
import ImgKurzy2 from "../../images/tanecni-kurzy/tanecni-kurzy-2.jpg"
import ImgKurzy3 from "../../images/tanecni-kurzy/tanecni-kurzy-3.jpg"
import ImgKurzy4 from "../../images/tanecni-kurzy/tanecni-kurzy-4.jpg"
import ImgKurzy5 from "../../images/tanecni-kurzy/tanecni-kurzy-5.jpg"
import ImgKurzy6 from "../../images/tanecni-kurzy/tanecni-kurzy-6.jpg"

const Kurzy = () => (

	<Row className="stk-kurzy">
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/tanecni-pripravka-pro-deti-skupina1">
				<img className="stk-kurzy__image" alt="Taneční přípravka pro děti - skupina 1" src={ImgKurzy1} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">děti</span>
				</div>
				<h2 className="stk-kurzy__name">Taneční přípravka pro&nbsp;děti - skupina&nbsp;1</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/tanecni-pripravka-pro-deti-skupina2">
				<img className="stk-kurzy__image" alt="Taneční přípravka pro děti - skupina 2" src={ImgKurzy2} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">děti</span>
				</div>
				<h2 className="stk-kurzy__name">Taneční přípravka pro&nbsp;děti - skupina&nbsp;2</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/soutezni-tanecni-pary-dc">
				<img className="stk-kurzy__image" alt="Soutěžní páry - třída D,C" src={ImgKurzy3} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">děti</span>
					<span className="stk-kurzy__tag">mládež</span>
					<span className="stk-kurzy__tag">dospělí</span>
				</div>
				<h2 className="stk-kurzy__name">Soutěžní páry - třída&nbsp;D,C</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/vrcholove-soutezni-tanecni-pary-bam">
				<img className="stk-kurzy__image" alt="Vrcholové soutěžní páry - třída B,A,M" src={ImgKurzy4} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">děti</span>
					<span className="stk-kurzy__tag">mládež</span>
					<span className="stk-kurzy__tag">dospělí</span>
				</div>
				<h2 className="stk-kurzy__name">Vrcholové soutěžní páry - třída&nbsp;B,A,M</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/tanecni-kurz-pro-am-zacatecnici">
				<img className="stk-kurzy__image" alt="Taneční kurz PRO-AM - začátečníci" src={ImgKurzy5} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">dospělí</span>
				</div>
				<h2 className="stk-kurzy__name">Taneční kurz PRO&#8209;AM - začátečníci</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
		<Col xs="6" sm="6" md="4" lg="4" xl="2">
			<Link className="stk-kurzy__item" to="/tanecni-kurzy/tanecni-kurz-pro-am-pokrocili">
				<img className="stk-kurzy__image" alt="Taneční kurz PRO-AM - pokročilí" src={ImgKurzy6} />
				<div className="stk-kurzy__tags">
					<span className="stk-kurzy__tag">dospělí</span>
				</div>
				<h2 className="stk-kurzy__name">Taneční kurz PRO&#8209;AM - pokročilí</h2>
				<div className="stk-button stk-button--small stk-kurzy__button">detail</div>
				<div className="stk-kurzy__bg"></div>
			</Link>
		</Col>
	</Row>

)

export default Kurzy