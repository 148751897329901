import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Kurzy from "../components/kurzy/kurzy"

import "../components/layout/layout.scss"
import "./index.scss"
import "./tanecni-kurzy.scss"

import ImgTreneriHybsova from "../images/treneri/libuse-hybsova.jpg"
import ImgTreneriPixa from "../images/treneri/jiri-pixa.jpg"
import ImgTreneriMaizlish from "../images/treneri/irina-maizlish.jpg"
import ImgTreneriZabystrzan from "../images/treneri/petr-zabystrzan.jpg"
import ImgTreneriMihalikova from "../images/treneri/monika-mihalikova.jpg"
import ImgTreneriRezler from "../images/treneri/zdenek-rezler.jpg"

const TanecniKurzy = () => (

	<Layout>
		<Seo title="Taneční kurzy" description="Taneční kurzy" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Taneční kurzy a semináře - naše nabídka</h1>
			<p>
				Pro naše páry nabízíme kvalitní systém výuky formou pravidelných seminářů pod&nbsp;vedením <Link to="../treneri">zkušených tanečních lektorů a&nbsp;trenérů</Link>.<br />
				Mimo vedené semináře a&nbsp;pravidelný practise je vždy k&nbsp;dispozici taneční sál pro&nbsp;individuální výuku nebo volný trénink.
			</p>

			<Kurzy></Kurzy>

			<h2 className="stk-h2">Semináře standardních a&nbsp;latinsko-amerických tanců v&nbsp;STK&nbsp;Praha</h2>

			<Row className="stk-seminare mb-5 mt-2">
				<Col xl="6">
					<div className="stk-seminare__item">
						<span className="stk-seminare__day">ÚT</span>
						<span className="stk-seminare__time">17:00<br /><span className="stk-seminare__dash">-</span><br />18:30</span>
						<img className="stk-seminare__photo" src={ImgTreneriHybsova} alt="Libuše Chytková" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Seminář - třída D,C</span>
							<span className="stk-seminare__subtitle">Libuše Chytková - LAT + STT</span>
						</span>
					</div>
					<div className="stk-seminare__item">
						<span className="stk-seminare__day">ÚT</span>
						<span className="stk-seminare__time">18:30<br /><span className="stk-seminare__dash">-</span><br />20:00</span>
						<img className="stk-seminare__photo" src={ImgTreneriMaizlish} alt="Irina Maizlish" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Latinský seminář - Irina</span>
							<span className="stk-seminare__subtitle">všechny taneční páry LAT</span>
						</span>
					</div>
					<Link className="stk-seminare__item" to="../practise">
						<span className="stk-seminare__day">ÚT</span>
						<span className="stk-seminare__time">20:00<br /><span className="stk-seminare__dash">-</span><br />22:00</span>
						<img className="stk-seminare__photo" src={ImgTreneriPixa} alt="Jiří Pixa" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Latinský practise STK</span>
							<span className="stk-seminare__subtitle">všechny soutěžní páry LAT</span>
						</span>
					</Link>
				</Col>
				<Col xl="6">
					<div className="stk-seminare__item">
						<span className="stk-seminare__day">ST</span>
						<span className="stk-seminare__time">19:40<br /><span className="stk-seminare__dash">-</span><br />22:45</span>
						<img className="stk-seminare__photo" src={ImgTreneriZabystrzan} alt="Petr Zabystrzan" />
						<img className="stk-seminare__photo" src={ImgTreneriMihalikova} alt="Monika Mihalíková" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Standardní seminář - Monika + Petr</span>
							<span className="stk-seminare__subtitle">všechny taneční páry STT</span>
						</span>
					</div>
					<div className="stk-seminare__item">
						<span className="stk-seminare__day">ČT</span>
						<span className="stk-seminare__time">17:00<br /><span className="stk-seminare__dash">-</span><br />18:30</span>
						<img className="stk-seminare__photo" src={ImgTreneriPixa} alt="Jiří Pixa" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Seminář - třída D,C</span>
							<span className="stk-seminare__subtitle">Jiří Pixa - LAT + STT</span>
						</span>
					</div>
					<div className="stk-seminare__item">
						<span className="stk-seminare__day">ČT</span>
						<span className="stk-seminare__time">18:30<br /><span className="stk-seminare__dash">-</span><br />20:00</span>
						<img className="stk-seminare__photo" src={ImgTreneriRezler} alt="Zdeněk Rezler" />
						<span className="stk-seminare__title">
							<span className="stk-seminare__name">Latinský seminář - Zdeněk</span>
							<span className="stk-seminare__subtitle">všechny taneční páry LAT</span>
						</span>
					</div>
				</Col>
			</Row>

		</div>
	</Layout>

)

export default TanecniKurzy